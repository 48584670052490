<template>
  <div v-frag>
    <employee-donations-table/>
    <!--    <employee-tabs-table :is-profile-page='false'/>-->
    <!--    <company-donation-breakdown/>-->
    <!--    <employee-donations/>-->
  </div>
</template>

<script>
// import CompanyDonationBreakdown from './components/CompanyDonationBreakdown'
// import EmployeeDonations from './components/EmployeeDonations'
// import EmployeeTabsTable from '@/views/employee/components/EmployeeTabsTable'
// , EmployeeDonations, CompanyDonationBreakdown


import EmployeeDonationsTable from './components/EmployeeDonationsTable'
export default {
  name: 'CharityInvolvementDonations',
  components: { EmployeeDonationsTable}
}
</script>

<style scoped>

</style>