<template>
  <v-card class='ma-5 pa-5 rounded-lg' elevation='3' max-width='100%'>
    <v-card-title class='headline'>
      {{ sections.donations || 'Donations' }}
    </v-card-title>
    <v-data-table
      :headers='donationHeaders'
      :items='donationHistory'
      :loading='loading'
      no-data-text='You have not made any donations yet.'
      flat
      hide-default-footer
    >
      <template #item.donationAmount={item}>
        <span> $ {{ item.donationAmount }} </span>
      </template>
    </v-data-table>
  </v-card>

</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'EmployeeDonationsTable',
  data() {
    return {
      loading: false,
      donationHeaders: [
        {
          text: 'Charity',
          align: 'start',
          sortable: true,
          value: 'charityName'
        },
        {
          text: 'Need',
          align: 'start',
          sortable: true,
          value: 'need'
        },
        {
          text: 'Date',
          align: 'start',
          sortable: true,
          value: 'date'
        },
        {
          text: 'Amount',
          align: 'start',
          sortable: true,
          value: 'donationAmount'
        }
      ]
    }
  },
  methods: {
    ...mapActions({
      getDonationHistory: 'employeeStore/getDonationHistory'
    }),
  },
  computed: {
    ...mapState({
      donationHistory: (state) => state.employeeStore.donationHistory,
      sections: state => state.cmsStore.sections
    })
  },
  async mounted() {
    this.loading = true
    await this.getDonationHistory()
    this.loading = false
  }
}
</script>

<style scoped>

</style>